<template>
    <v-container fluid>
       <v-text-field label="部門コード" value="" v-model="MTpBsTnpCd" :counter="5"  clearable dense/>
       <v-text-field label="部門名称" value="" v-model="MTpBsTnpNm"  clearable dense/>
    <v-btn color="warning" @click="submit">部門登録</v-btn>
    <v-btn color="error" class="mr-4" @click="reset">リセット</v-btn>
    </v-container>
</template>
<script>
    import axios from 'axios';
    export default {
    data: () => ({
        valid: true,
        MTpBsTnpCd: '', //部署コード
        MTpBsTnpNm: '', //部署名称
    }),
  
    methods: {
        //--------------------------------------------------------------------
        //部門マスタ追加更新
        //--------------------------------------------------------------------
        submit() {
            axios
           .get('/api/TpBsMstADD',{
               params: {
                MTpBsTnpCd: this.MTpBsTnpCd,
                MTpBsTnpNm: this.MTpBsTnpNm
               }
           });
        },
        //画面のリセット
        reset () {
            this.$refs.form.reset()
        },
   }
}
</script>